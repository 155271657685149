import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/www/src/templates/mdx-page-layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "hug-a-tree"
    }}><a parentName="h2" {...{
        "href": "/lessons/hug-a-tree/"
      }}>{`Hug a Tree`}</a></h2>
    <p>{`Start off with 5 minutes of Hug a Tree meditation.`}</p>
    <h2 {...{
      "id": "shifting-forward-sitting-backward"
    }}>{`Shifting Forward, Sitting Backward`}</h2>
    <p>{`Like `}<a parentName="p" {...{
        "href": "/lessons/shifting-weight/"
      }}>{`Sitting Left and Right`}</a>{` but now in a bow stance.`}</p>
    <h2 {...{
      "id": "footwork-stepping-forward"
    }}>{`Footwork: Stepping Forward`}</h2>
    <p>{`Stepping forward in a bow stance.`}</p>
    <h2 {...{
      "id": "arms-brush-and-push"
    }}>{`Arms: Brush and Push`}</h2>
    <p>{`Cross the arms in the middle, circle down and out to “gorilla arms”, straightend and recurve into “wave”, then close into cross hands.`}</p>
    <h2 {...{
      "id": "posture-brush-knee-and-push"
    }}>{`Posture: Brush Knee and Push`}</h2>
    <p>{`Putting all the above together into the taiji posture called Brush Knee and Push.`}</p>
    <p><a parentName="p" {...{
        "href": "/lessons"
      }}>{`Back to Lessons`}</a></p>
    <p><a parentName="p" {...{
        "href": "/lessons/lesson-2/"
      }}>{`Back to Lesson 2`}</a></p>
    <p><a parentName="p" {...{
        "href": "/lessons/lesson-4/"
      }}>{`On to Lesson 4`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      